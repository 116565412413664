<template>
  <div class="networdError">
    <h1>
      Uh-oh
    </h1>
    <h3>
      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
      Veritatis nesciunt qui aliquid<a href="#" @click="$router.go(-1)"> Click here</a>  to try again perferendis animi iste dolore 
      tempore recusandae accusamus.
    </h3>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>